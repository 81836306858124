import { Fragment } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { pages } from "./routes";

const Footer = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return pages.some(
    (page) => page.path === pathname || page.path + "/" === pathname
  ) && !pathname.startsWith("/dashboard") ? (
    <Fragment>
      <footer className="py-12 mt-auto bg-slate-800">
        <div className="max-w-screen-xl lg:mx-auto mx-8">
          <div className="flex lg:flex-row flex-col lg:items-start items-center justify-between w-full gap-4">
            <div className="flex flex-col lg:items-start items-center gap-4">
              <h5 className="text-lg font-semibold text-white">
                What We Offer
              </h5>
              <a href="#" className="text-white">
                Membership
              </a>
              <a href="#" className="text-white">
                Our Knowledge
              </a>
            </div>
            <div className="flex flex-col lg:items-start items-center gap-4">
              <h5 className="text-lg font-semibold text-white">Support</h5>
              <a href="#" className="text-white">
                Contact Us
              </a>
            </div>
            <div className="flex flex-col lg:items-start items-center gap-4">
              <h5 className="text-lg font-semibold text-white">Company</h5>
              <a href="#" className="text-white">
                Team
              </a>
              <a href="#" className="text-white">
                Careers
              </a>
            </div>
            <div className="flex flex-col lg:items-start items-center gap-4">
              <h5 className="text-lg font-semibold text-white">
                Subscribe Today
              </h5>
              <input
                className="py-3 px-5 border border-slate-800 focus:border-white placeholder:text-gray-500 focus-visible:outline-none"
                placeholder="Email Address"
              />
              <a
                href="#"
                className="btn-primary uppercase mt-8 py-2 px-8 text-white text-sm border bg-orange-400 border-orange-400 hover:bg-orange-500 hover:border-orange-500"
              >
                Subcribe
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* <footer className="bg-white py-4">
        <div className="max-w-screen-xl lg:mx-auto mx-8 flex lg:flex-row flex-col items-center justify-center">
          <p className="text-blue-primary font-medium mx-4 lg:pb-0 pb-2">
            &copy; LabCare Diagnostics. All Right Reserved{" "}
            {new Date().getFullYear()}.
          </p>
          <a
            href="/privacy-policy"
            className="lg:border-l border-t lg:border-t-0 border-neutral-300 px-4 lg:py-0 py-2 text-blue-primary font-medium"
          >
            Privacy Policy
          </a>
          <a
            href="/terms-and-conditions"
            className="lg:border-l border-t lg:border-t-0 border-neutral-300 px-4 lg:py-0 py-2 text-blue-primary font-medium"
          >
            Terms & Conditions
          </a>
          <a
            href="/accessibility-statement"
            className="lg:border-l border-t lg:border-t-0 border-neutral-300 px-4 lg:py-0 py-2 text-blue-primary font-medium"
          >
            Accessibility Statement
          </a>
        </div>
      </footer> */}
    </Fragment>
  ) : null;
};

export default Footer;
