import { Fragment, useEffect, useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaArrowRight, FaRegUser } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { Collapse, initTE } from "tw-elements";
import useScrollPosition from "../hooks/useScrollPosition";
import useWindowSize from "../hooks/useWindowSize";
import NavButton from "./NavButton";
import { pages, routes } from "./routes";

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);
  const size = useWindowSize();
  const location = useLocation();
  const pathname = location.pathname;
  const scrollPosition = useScrollPosition();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  useEffect(() => {
    initTE({ Collapse });
  }, []);

  return (
    <Fragment>
      <div
        className={classNames(
          pathname === "/" ? "fixed" : "sticky top-0",
          "w-full z-50"
        )}
      >
        <nav
          className={classNames(
            pages.some(
              (page) => page.path === pathname || page.path + "/" === pathname
            ) && !pathname.startsWith("/dashboard")
              ? "lg:flex hidden"
              : "hidden",
            "bg-slate-800 w-full py-2 px-2 flex-col justify-center items-center z-50"
          )}
        >
          <div className="max-w-screen-2xl mx-auto flex items-center justify-end w-full">
            <div className="flex items-center w-fit mr-20">
              <a href="/dashboard" className="text-white text-sm leading-none">
                DASHBOARD [TEST LINK]
              </a>
            </div>
            <div className="flex items-center gap-4 w-fit">
              <div className="pr-4 border-r border-white flex items-center">
                <a href="#" className="text-white text-sm leading-none">
                  SIGN UP
                </a>
              </div>

              <a href="#" className="text-white text-sm leading-none">
                LOG IN
              </a>
            </div>
          </div>
        </nav>
        <nav
          className={classNames(
            pages.some(
              (page) => page.path === pathname || page.path + "/" === pathname
            ) && !pathname.startsWith("/dashboard")
              ? ""
              : "hidden",
            scrollPosition > 0 || pathname !== "/"
              ? "bg-white shadow-md"
              : "bg-transparent",
            "w-full h-[62px] px-2 flex flex-col justify-center items-center z-40"
          )}
        >
          <div className="max-w-screen-2xl flex justify-between items-center mx-auto w-full">
            <div className="flex lg:justify-start justify-between items-center">
              <button
                data-toggle="navbar"
                onClick={() => setExpanded(!expanded)}
                type="button"
                className={classNames(
                  scrollPosition > 0 ? "text-slate-800" : "text-white",
                  "lg:hidden md:text-3xl text-xl"
                )}
                aria-controls="navbar"
                aria-expanded="false"
              >
                <GiHamburgerMenu />
              </button>
              <a href="/" className="w-36">
                <img src="" className="w-full" alt="Logo" />
              </a>
              <a
                href="#"
                className={classNames(
                  scrollPosition > 0 ? "text-slate-800" : "text-white",
                  "lg:hidden md:text-3xl text-xl"
                )}
              >
                <FaRegUser />
              </a>
              <ul
                className={classNames(
                  size.width < 1024 ? "hidden" : "flex",
                  "items-center ml-4 gap-10"
                )}
              >
                {/* <li>
                  <NavButton to="#" primary="Products" />
                </li> */}
                <li>
                  <NavButton to="/membership" primary="Membership" />
                </li>
                <li>
                  <NavButton to="/blog" primary="Knowledge" />
                </li>
                <li>
                  <NavButton to="/team" primary="Team" />
                </li>
              </ul>
            </div>
            <a
              className={classNames(
                scrollPosition > 0 || pathname !== "/"
                  ? "lg:block hidden"
                  : "hidden",
                "btn-primary py-2 px-8 text-white text-sm border bg-orange-400 border-orange-400 hover:bg-orange-500 hover:border-orange-500"
              )}
              href="#"
            >
              GET STARTED
            </a>
          </div>
          <div
            className={classNames(
              expanded ? "max-h-[700px]" : "max-h-0",
              "block lg:max-h-full w-full lg:w-auto transition-all duration-700 rounded-none md:rounded-2xl md:container overflow-hidden lg:hidden"
            )}
            id="navbar"
          >
            {size.width < 1024 ? (
              <div className="flex flex-col bg-white">
                <NavButton to="/" primary="Home" />
                <div className="accordion accordion-flush" id="mobileMenu">
                  <NavButton
                    to="#"
                    primary="Patient"
                    id="One"
                    dropdownLinks={[
                      {
                        primary: "Patient Portal",
                        to: "/",
                      },
                      {
                        primary: "Lab Locations",
                        to: "/location",
                      },
                      {
                        primary: "Covid Testing",
                        to: "/covid-mobile-testing",
                      },
                      /* {
                      primary: "Tests",
                      to: "/tests",
                    }, */
                      {
                        primary: "Appointment",
                        to: "/appointment",
                      },
                      {
                        primary: "Insurance",
                        to: "/insurance",
                      },
                      {
                        primary: "Billing",
                        to: "/billing",
                      },
                      {
                        primary: "Pay Your Bill",
                        to: "/billing",
                      },
                    ]}
                  />
                  <NavButton
                    to="#"
                    primary="Physicians"
                    id="Two"
                    dropdownLinks={[
                      {
                        primary: "Forms",
                        to: "/forms",
                      },
                      {
                        primary: "Insurance",
                        to: "/insurance",
                      },
                      {
                        primary: "Supplies",
                        to: "/supplies",
                      } /* 
                    {
                      primary: "Tests",
                      to: "/tests",
                    }, */,
                      {
                        primary: "Physicians's Portal",
                        to: "/",
                      },
                    ]}
                  />
                  <NavButton
                    to="#"
                    primary="About"
                    id="Three"
                    dropdownLinks={[
                      {
                        primary: "About Us",
                        to: "/about",
                      },
                      {
                        primary: "Accredition & Licensing",
                        to: "/accredition-licensing",
                      },
                      {
                        primary: "News",
                        to: "/news",
                      },
                    ]}
                  />
                </div>
                <NavButton to="/services" primary="Services" />
                <NavButton to="/careers" primary="Careers" />
                <NavButton to="/contact-us" primary="Contact" />
              </div>
            ) : null}
          </div>
        </nav>
      </div>

      {routes()}
    </Fragment>
  );
};

export default Navbar;
